import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_DATASETS_FIELDS: string = typeCreatorV2(
  'DashboardBuilderReducer',
  'SetDataFields'
);

export const SET_BUILDER_CONFIGURATION: string = typeCreatorV2(
  'DashboardBuilderReducer',
  'SetBuilderConfiguration'
);

export const SET_ELEMENT_SELECTED: string = typeCreatorV2(
  'DashboardBuilderReducer',
  'SetElementSelected'
);

export const SET_PALLETE_VISIBILITY: string = typeCreatorV2(
  'DashboardBuilderReducer',
  'SetPalleteVisibility'
);

export const SET_BUILDER_GLOBALS: string = typeCreatorV2(
  'DashboardBuilderReducer',
  'SetBuilderGlobals'
);

export const SET_SELECTED_DASHBOARD: string = typeCreatorV2(
  'DashboardBuilderReducer',
  'SetSelectedDashboard'
);

export const SET_DASHBOARDS: string = typeCreatorV2(
  'DashboardBuilderReducer',
  'SetDashboard'
);

export const SAVE_DASHBOARD: string = typeCreatorV2(
  'DashboardBuilderReducer',
  'SaveDashboard'
);

// export const SET_: string = typeCreatorV2(
//   'DashboardBuilderReducer',
//   'SaveDashboard'
// );
