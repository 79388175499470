export const generateRandomSequence = (
  from: number,
  to: number,
  size: any
): number[] =>
  //   Array.from(null, { length: 40 }).map((x) => Math.random() * to + from);
  Array.from({ length: size }, () =>
    Math.floor(Math.random() * (to - from) + from)
  );

export const generateRandomNumber = (from: number, to: number): number =>
  Math.floor(Math.random() * (to - from) + from);

export const getRandomColor = (): string => {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
