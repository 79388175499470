import { IAction, StateStatus } from 'redux/utils/common';
import * as Types from './types';

interface IState {
  registerUserStatus: StateStatus | null | undefined;
}

const initialState: IState = {
  registerUserStatus: null,
};

const RegisterReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.REGISTER_USER:
      return {
        ...state,
        registerUserStatus: action.status,
      };
    default:
      return {
        ...state,
      };
  }
};

export default RegisterReducer;
